import React, { useState, useEffect } from "react";
import logoPanorama from "../../images/panorama-by-aulasneo.png";
import Menu from "./Menu";
import MenuResponsive from "./MenuResponsive";
import "../../styles.css"

function Header({ userAttributes, signOut, userGroup }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isResponsive, setIsResponsive] = useState(windowWidth <= 528);
  
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setWindowWidth(width);
      setIsResponsive(width <= 528);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="header">
      {userAttributes && (
        <div className="content-header">
          <img src={logoPanorama} alt="Logo" className="logoPanorama" />
          {isResponsive ? (
            <MenuResponsive
              userAttributes={userAttributes}
              signOut={signOut}
              userGroup={userGroup}
            />
          ) : (
            <Menu
              userAttributes={userAttributes}
              signOut={signOut}
              userGroup={userGroup}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default Header;
