import React, { useContext, useState, useEffect, useRef } from "react";
import { DashboardTypeContext } from "../DashboardContext";
import "../Tabs/stylesTabs.css";

const Tabs = () => {
  const { changeDashboardType, dashboardType, response } =
    useContext(DashboardTypeContext);

  const [itemsMenu, setItemsMenu] = useState([]);
  const [showTabs, setShowTabs] = useState();
  const [showMenu, setShowMenu] = useState(false);

  const menuRef = useRef(null);

  const handleMenuClick = (value) => {
    changeDashboardType(value);
    setShowTabs(!showTabs);
  };

  const ChanngeShowTabs = () => {
    setShowTabs(!showTabs);
    setShowMenu(true)
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowMenu(false);
      setShowTabs(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (response && response.length > 0 && itemsMenu.length === 0) {
      let updatedItemsMenu = [];
      for (let i = 0; i < response.length; i++) {
        updatedItemsMenu.push(response[i].displayName);
      }
      setItemsMenu(updatedItemsMenu);
    }
  }, [response, itemsMenu]);

  return (
    <div ref={menuRef} className="content-tabs">
      <div className="sidebar">
        <button className="buttonMenu" onClick={ChanngeShowTabs}>Dashboards</button>
      </div>

      {showMenu && (
        <div className={`tab-container ${showTabs ? 'open' : 'close'}`}>
          {itemsMenu.map((item, index) => (
            <div id={`tab${index}`} className="tab" key={index}>
              <a
                className={`${item === dashboardType ? "selected" : ""}`}
                aria-current="page"
                href={`#${item}`}
                onClick={() => handleMenuClick(item)}
              >
                {item}
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Tabs;
