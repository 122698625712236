import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { DashboardTypeContext } from "../DashboardContext";
import logoPanorama from "../../images/panorama-by-aulasneo.png";
import { FaAngleRight } from "react-icons/fa";
import { SiLinkedin, SiInstagram, SiFacebook } from "react-icons/si";
import favicon from "../../images/favicon2.png";
import "./stylesInit.css";

function Init() {
  const { t, i18n } = useTranslation(["global"]);
  const { changeLanguage } = useContext(DashboardTypeContext);

  return (
    <div className="init-container">
      <div className="language-switch">
        <button className="btn-banderas" onClick={changeLanguage}>
          {t("welcome.language") + "  "}
          {i18n.language === "es" ? (
            <img
              src="https://s.w.org/images/core/emoji/14.0.0/svg/1f1ea-1f1f8.svg"
              alt="Español"
              width={25}
              height={25}
            />
          ) : (
            <img
              src="https://s.w.org/images/core/emoji/14.0.0/svg/1f1fa-1f1f8.svg"
              alt="Inglés"
              width={25}
              height={25}
            />
          )}
        </button>
      </div>

      <img src={logoPanorama} alt="Logo" className="logo-panorama" />
      
      <Link className="learnmore" to="https://aulasneo.com/open-edx-analytics/">
      <h1 className="welcome-init">{t("welcome.link")}</h1>
      </Link>

      <Link to="/login" className="link">
        {t("welcome.discover")} <FaAngleRight />
      </Link>
      <div className="footer-init">
        <div className="icons-container">
          <a href="https://www.linkedin.com/company/aulasneo/">
            <SiLinkedin className="social-link" />
          </a>
          <a href="https://www.facebook.com/aulasneo">
            <SiFacebook className="social-link" />
          </a>
          <a href="https://www.instagram.com/aulasneo/">
            <SiInstagram className="social-link" />
          </a>
          <a href="https://www.aulasneo.com/">
            <img src={favicon} alt="web" className="web-icon" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Init;
