import React from "react";
import logo from "../../images/logo.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./stylesFooter.css";

function Footer() {
  const { t } = useTranslation(["global"]);

  return (
    <div className="footer">
      <a href="https://www.aulasneo.com">
        <img src={logo} alt="Logo" className="logoFooter"></img>
      </a>
      <a href="https://aulasneo.com/contact/">{t("footer.contant")}</a>
      <a href="https://aulasneo.com/terms-of-use/"></a>
      <Link to="/terms-of-use">{t("footer.terms")}</Link>
      <div className="reserved">&copy;{t("footer.reserved")}</div>
    </div>
  );
}

export default Footer;
