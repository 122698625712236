import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function WelcomeHome() {
  const { t } = useTranslation(["global"]);

  return (
    <div className="homeWelcome">
      <h2> {t("welcome.titleWelcome")}</h2>
      <Link to="/dashboard" className="button-contact">
        {t("welcome.discover")}
      </Link>
      <p>{t("welcome.mesageWelcome")}</p>
    </div>
  );
}

export default WelcomeHome;
