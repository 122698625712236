import React from "react";
import { useTranslation } from "react-i18next";
import "./stylesHome.css";
import { Link } from "react-router-dom";

function WelcomeHomeDemo() {
  const { t } = useTranslation(["global"]);

  return (
    <div className="homeWelcome">
      <h2>{t("welcome.titleWelcome")}</h2>
      <p>{t("welcome.mesageWelcomeDemo1")}</p>
      <Link to="/dashboard" className="button-contact">
        {t("welcome.discover")}
      </Link>
      <p>{t("welcome.mesageWelcomeDemo2")}</p>
      <a className="button-contact" href="https://aulasneo.com/contact/">
        {t("welcome.contant")}
      </a>
    </div>
  );
}

export default WelcomeHomeDemo;
