import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { fetchUserAttributes, fetchAuthSession } from "aws-amplify/auth";
import { CircularProgress } from "@mui/material";
import awsExports from "./aws-exports";
import { DashboardTypeProvider } from "./components/DashboardContext";

import AuthUserForm from "./components/Authn/AuthUserForm";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import WelcomeHome from "./components/Home/WelcomeHome";
import WelcomeHomeDemo from "./components/Home/WelcomeHomeDemo";
import Home from "./components/Home/Home";
// import Profile from "./components/Profile/Profile";
import Init from "./components/Init/Init";
import TermsOfUse from "./components/TermsOfUse/TermsOfUse";

Amplify.configure(awsExports)

function App() {
  
  const [userAttributes, setUserAttributes] = useState(null);
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const [userGroup, setUserGroup] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("usuario", user)
        if (user) {
          const attributes = await fetchUserAttributes();
          const data = await fetchAuthSession();
          let group = [];
          if (data.tokens.idToken.payload["cognito:groups"]){
             group = data.tokens.idToken.payload["cognito:groups"];
          }
          if (!attributes["custom:quicksightUser"] && !group.includes("authorIA")){
            group.push("authorIA");
          }
          setUserGroup(group);
          setUserAttributes(attributes); 
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [user]);

  const renderAuthContent = (Component) =>
    user ? (
      <>
        <Header
          userAttributes={userAttributes}
          signOut={signOut}
          userGroup={userGroup}
        />
        <Component
          userAttributes={userAttributes}
          signOut={signOut}
          userGroup={userGroup}
        />
        <Footer />
      </>
    ) : (
      <AuthUserForm />
    );

  return (
    <Router>
      <DashboardTypeProvider>
        <Routes>
          <Route path="/" element={<Init />} />
          <Route path="*" element={<Navigate to="/home" />} />
          <Route path="login" element={renderAuthContent(MainContent)} />
          <Route path="home" element={renderAuthContent(MainContent)} />
          <Route path="author" element={renderAuthContent(AuthorContent)} />
          <Route path="AIPanorama" element={renderAuthContent(AIPanoramaContent)} />
          <Route path="terms-of-use" element={renderAuthContent(TermsOfUseContent)} />
          <Route path="dashboard" element={renderAuthContent(DashboardContent)} />
          {/* <Route path="profile" element={renderAuthContent(ProfileContent)} /> */}
        </Routes>
      </DashboardTypeProvider>
    </Router>
  );
}

function MainContent({ userAttributes, signOut, userGroup }) {
  if (!userAttributes) {
    return (
      <div className="home">
        <CircularProgress />
      </div>
    );
  }
  return (
    <>
      {userAttributes["custom:dashboardType"] === "basic" ? (
        <div className="containerApp">
          <WelcomeHome />
        </div>
      ) : (
        <div className="containerApp">
          <WelcomeHomeDemo />
        </div>
      )}
    </>
  );
}

function AuthorContent({userGroup}) {
  return <Home dashboardFunction="author" />;
}

function DashboardContent() {
  return <Home dashboardFunction="dashboard" />;
}

function AIPanoramaContent() {
  return <Home dashboardFunction="authorIA" />;
}

// function ProfileContent() {
//   return <Profile dashboardFunction="profile" />;
// }

function TermsOfUseContent() {
  return <TermsOfUse />;
}

export default App;
