import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DashboardTypeContext } from "../DashboardContext";
import "./stylesHeader.css"

const MenuResponsive = ({ userAttributes, signOut, userGroup }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [currentPath, setCurrentPath] = useState("/");
  const location = useLocation();
  const { t, i18n } = useTranslation(["global"]);
  const navigate = useNavigate();

  const { changeLanguage } = useContext(DashboardTypeContext);

  const handleMenuItemClick = () => {
    setMenuOpen(false);
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      localStorage.clear();
      sessionStorage.clear();
      navigate('/');
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);

  return (
    <div className="menu-responsive">
      <button className="btn-banderas-resp" onClick={changeLanguage}>
        {i18n.language === "es" ? (
          <img
            src="https://s.w.org/images/core/emoji/14.0.0/svg/1f1ea-1f1f8.svg"
            alt="Español"
            width={25}
            height={25}
          />
        ) : (
          <img
            src="https://s.w.org/images/core/emoji/14.0.0/svg/1f1fa-1f1f8.svg"
            alt="Inglés"
            width={25}
            height={25}
          />
        )}
      </button>
      <div className="dropdown menu-title submenu-responsive">
        <div className="icon">
          <button
            className="btn dropdown menu-title"
            type="button"
            onClick={() => setMenuOpen(!menuOpen)}
            aria-expanded={menuOpen ? "true" : "false"}
            aria-label="Toggle navigation"
          >
            <svg
              className="icon-svg"
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="35"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
              />
            </svg>
          </button>
          {userAttributes && (
            <div
              className={`collapse navbar-collapse ${menuOpen ? "show" : ""}`}
              id="navbarScroll"
            >
              <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav">
                <li className="dropdown title-menu-responsive">
                  <button
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarScrollingDropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded={menuOpen ? "true" : "false"}
                  >
                    {(userAttributes.given_name ? userAttributes.given_name : "") + " " + (userAttributes.family_name ? userAttributes.family_name : "")}
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarScrollingDropdown"
                  >
                    {/* <li>
                      <Link to="/profile">
                        <button className="dropdown-item signOut"  onClick={() => setMenuOpen(!menuOpen)}>
                          {t("header.profile")}
                        </button>
                      </Link>
                    </li> */}
                    <li>
                      <button
                        className="dropdown-item signOut"
                        onClick={handleSignOut}
                      >
                        {t("header.signOut")}
                      </button>
                    </li>
                  </ul>
                </li>

                <li className="navbar-nav align-items-start">
                  <Link to="/home" onClick={handleMenuItemClick}>
                    <button
                      className="btn btn-padding-x-0"
                      disabled={currentPath === "/home"}
                      aria-expanded={menuOpen ? "true" : "false"}
                    >
                      {t("header.home")}
                    </button>
                  </Link>
                  <Link to="/dashboard" onClick={handleMenuItemClick}>
                    <button
                      className="btn btn-padding-x-0"
                      aria-expanded={menuOpen ? "true" : "false"}
                      disabled={currentPath.includes("/dashboard")}
                      value="dashboard"
                    >
                      {t("header.dashboard")}
                    </button>
                  </Link>
                  {userGroup?.includes("author")  && (
                    <Link to="/author" onClick={handleMenuItemClick}>
                      <button
                        className="btn"
                        aria-expanded={menuOpen ? "true" : "false"}
                        disabled={currentPath.includes("/author")}
                        value="author"
                      >
                        {t("header.author")}
                      </button>
                    </Link>
                  )}
                  {userGroup?.includes("authorIA") && (
                    <Link to="/AIPanorama" onClick={handleMenuItemClick}>
                      <button
                        className="btn"
                        aria-expanded={menuOpen ? "true" : "false"}
                        disabled={currentPath.includes("/AIPanorama")}
                        value="author"
                      >
                        AI Panorama
                      </button>
                    </Link>
                  )}
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MenuResponsive;
