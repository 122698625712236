import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DashboardTypeContext } from "../DashboardContext";
import "./stylesHeader.css"

const Menu = ({ userAttributes, signOut, userGroup }) => {
  const [currentPath, setCurrentPath] = useState("/");
  const location = useLocation();
  const { t, i18n } = useTranslation(["global"]);
  const { changeLanguage } = useContext(DashboardTypeContext);
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);

  const handleSignOut = async () => {
    try {
      await signOut();
      localStorage.clear();
      sessionStorage.clear(); 
      navigate('/');
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  return (
    <div className="menu">
      {userAttributes && (
        <div className="content-menu">
          <div className="menu-title">
            <Link to="/home">
              <button className="btn" disabled={currentPath === "/home"}>
                {t("header.home")}
              </button>
            </Link>
            <Link to="/dashboard">
              <button
                className="btn"
                disabled={currentPath.includes("/dashboard")}
              >
                {t("header.dashboard")}
              </button>
            </Link>
            {userGroup?.includes("author") && (
              <Link to="/author">
                <button
                  className="btn"
                  disabled={currentPath.includes("/author")}
                >
                  {t("header.author")}
                </button>
              </Link>
            )}
            {(userGroup?.includes("authorIA")) && (
              <Link to="/AIPanorama">
                <button
                  className="btn"
                  disabled={currentPath.includes("/AIPanorama")}
                >
                  AI Panorama
                </button>
              </Link>
            )}
          </div>
          <button className="btn-banderas" onClick={changeLanguage}>
            {i18n.language === "es" ? (
              <img
                src="https://s.w.org/images/core/emoji/14.0.0/svg/1f1ea-1f1f8.svg"
                alt="Español"
                width={25}
                height={25}
              />
            ) : (
              <img
                src="https://s.w.org/images/core/emoji/14.0.0/svg/1f1fa-1f1f8.svg"
                alt="Inglés"
                width={25}
                height={25}
              />
            )}
          </button>
          <div className="dropdown">
            <button
              className="btn dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {(userAttributes.given_name ? userAttributes.given_name : "") + " " + (userAttributes.family_name ? userAttributes.family_name : "")}
            </button>
            <ul className="dropdown-menu">
              {/* <li>
                <Link to="/profile">
                  <button className="dropdown-item signOut">
                    {t("header.profile")}
                  </button>
                </Link>
              </li> */}
              <li>
                <button className="dropdown-item signOut" onClick={handleSignOut}>
                  {t("header.signOut")}
                </button>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Menu;
