import React from 'react';
import {
  Authenticator,
  CheckboxField,
  useAuthenticator,
  Text,
  View,
  useTheme
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './stylesAuth.css';
import { useTranslation } from 'react-i18next';
import TermsOfUse from '../../components/TermsOfUse/TermsOfUse';

function AuthUserForm({ initialState = 'signIn' }) {
  const { t } = useTranslation(['global']);

  const formFields = {
    signIn: {
      username: {
        label: 'Email',
        placeholder: 'Enter your Email',
      },
    },
  }

  const componentFooter = {
    Footer() {
      const { tokens } = useTheme();
  
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text color={tokens.colors.neutral[80]}>
            &copy; All Rights Reserved
          </Text>
        </View>
      );
    },

  }

  const signUpConfig = {
    SignUp: {
      FormFields() {
        const { validationErrors } = useAuthenticator();
        return (
          <>
            <Authenticator.SignUp.FormFields />
            <div className="amplify-flex amplify-field amplify-textfield">
              <label className="amplify-label">First Name</label>
              <input
                className="amplify-input amplify-field-group__control"
                type="text"
                name="given_name"
                placeholder="Enter your first name"
              />
            </div>
            <div className="amplify-flex amplify-field amplify-textfield">
              <label className="amplify-label">Last Name</label>
              <input
                className="amplify-input amplify-field-group__control"
                type="text"
                name="family_name"
                placeholder="Enter your last name"
              />
            </div>
            <div className="amplify-flex amplify-field amplify-textfield">
              <label className="amplify-label">Organization</label>
              <input
                className="amplify-input amplify-field-group__control"
                type="text"
                name="custom:organization"
                placeholder="Enter your organization"
              />
            </div>

            <div className="input-chk">
              <CheckboxField
                className="input-chk"
                errorMessage={t('auth.acceptTerms')}
                hasError={!!validationErrors.acknowledgement}
                name="acknowledgement"
                value="yes"
              />
              <button
                type="button"
                className="chk-terms"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                {t('footer.terms')}
              </button>
            </div>
            <div
              className="modal fade"
              id="exampleModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="modal-header">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <TermsOfUse />
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      },
    },
  };

  const signUpServices = {
    async validateCustomSignUp(formData) {
      if (!formData.acknowledgement) {
        return {
          acknowledgement: 'You must agree to the Terms and Conditions',
        };
      }
      if (!formData.given_name) {
        return {
          given_name: t('global:formErrors.firstNameRequired'),
        };
      }
      if (!formData.family_name) {
        return {
          family_name: t('global:formErrors.lastNameRequired'),
        };
      }
      if (!formData['custom:organization']) {
        return {
          'custom:organization': t('global:formErrors.organizationRequired'),
        };
      }
    },
  };

  return (
    <Authenticator
      initialState={initialState}
      formFields={formFields}
      components={{...componentFooter, ...signUpConfig}}
      services={signUpServices}
      loginMechanisms={['email']}
    />
  );
}

export default AuthUserForm;
